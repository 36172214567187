// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Rails')

console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

import { createApp } from 'vue/dist/vue.esm-bundler';  
//     <%= vite_javascript_tag 'application.jsx' %>

import i18n from '../setup/i18n'
import router from '../setup/router'
import api from '../setup/api'

import MyApp from "../app/MyApp.vue"

// Quasar UI Framework
import { Quasar } from 'quasar'
import '@quasar/extras/roboto-font-latin-ext/roboto-font-latin-ext.css'
import '@quasar/extras/material-icons/material-icons.css'
import 'quasar/src/css/index.sass'

const _app = createApp(MyApp)  

_app.use(i18n)
_app.use(router)
_app.use(api, {})

_app.use(Quasar, {
    plugins: {}, // import Quasar plugins and add here
})

_app.config.errorHandler = function (err, vm, info) {
    console.log(err);
    console.log(vm);
    console.log(info);
  }
  

_app.mount('#app');  
console.log("app", app);  
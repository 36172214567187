<script setup>
    console.log("kek");

    import CategoryMenu from './layout/CategoryMenu.vue';

    import { useI18n } from "vue-i18n";
    const i18n  = useI18n();
    console.log(i18n.availableLocales)

    function changeLang (locale) {
        i18n.locale = locale
    }
</script>

<template>
    <div class="page-wrapper">
        <q-layout view="lhr Lpr lfr">

            <q-header reveal elevated class="bg-white text-white">
                <img src="https://www.eamf.lv/static/version1718895234/frontend/AMFurnitura/theme/lv_LV/images/logo_amf.svg" />

                <q-toolbar>
                    <q-toolbar-title>
                       <span class="text-secondary"> {{ $t("example") }} </span>
                    <div class="lang">
                        <div v-for="locale in $i18n.availableLocales" @click="$i18n.locale = locale">{{locale}}</div>
                    </div>
                    <!-- <div class="locale-changer">
                        <select v-model="$i18n.locale">
                            <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">{{ locale }}</option>
                        </select>
                    </div> -->
                    </q-toolbar-title>
                </q-toolbar>
            </q-header>

            <q-page-container>
                <q-page style="padding-top: 60px" class="q-pa-md">
                    <router-view /> <!-- this is the actual page content-->

                    <q-page-sticky position="top" expand class="bg-white text-darkgray">
                        <q-toolbar class="limit-width">
                            <CategoryMenu />
                        </q-toolbar>
                    </q-page-sticky>
                </q-page>
            </q-page-container>

            <q-footer class="bg-grey-8 text-white">
                <q-toolbar>
                    <q-toolbar-title>
                        <strong>Current route path:</strong> {{ $route.fullPath }}
                    </q-toolbar-title>
                </q-toolbar>
                <q-toolbar>
                    <RouterLink to="/">{{$t("home")}}</RouterLink>
                    <RouterLink to="/about">{{$t("about")}}</RouterLink>

                </q-toolbar>
            </q-footer>

        </q-layout>
    </div>
</template>

<style lang="scss">
    .page-wrapper {
        max-width: 1260px;
        margin-left: auto;
        margin-right: auto;
    }

    .limit-width {
        max-width: 1260px;
    }

    .q-header {
        margin: 0 650px;

        .lang {
            float: right;

            div {
                display: inline;
                color: black;
                margin-left: 13px;
                cursor: pointer
            }
        }

    }
    
    .q-footer a {
        margin-left: 40px;
        font-size: 20px;
        color: white;
        text-decoration: none;
    }
</style>
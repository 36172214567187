<script setup>
    import { ref, inject, watch } from 'vue'
    const fetch = inject("fetchData")

    const categories = ref([])
    const loaded = ref(false)

    const menus = ref({})

    const menuOvers = ref({})

    const listOvers = ref({})

    fetch('categories/tree').then( (response) => {
        categories.value = response.data
        loaded.value = true
        // for (cat in categories){}
    })

    watch(menuOvers, (oldValue, newValue) => { watchMenus() }, {deep: true})
    watch(listOvers, (oldValue, newValue) => { watchMenus() }, {deep: true})

    function watchMenus(){
        console.log(menus.value)
        console.log(menuOvers.value)
        for (const cat in categories.value){
            if (menuOvers.value[cat.id] && menuOvers.value[cat.id]){
                menus.value[cat.id] = true
            } else {
                menus.value[cat.id] = false
            }
        }
    }
</script>

<template>
    <template v-if="loaded" v-for="cat in categories">
        <div class="bg-white q-pa-md" @mouseover="menuOvers[cat.id] = true" @mouseout="menuOvers[cat.id] = false">
            <div class="fit flex flex-center text-center non-selectable">
                <span>{{cat.name}}</span>
            </div>

            <q-menu v-model="menus[cat.id]">
                <q-list style="min-width: 100px" @mouseover.native="listOvers[cat.id] = true" @mouseout.native="listOvers[cat.id] = false">
                    <template v-for="child in cat.children">
                        <q-item clickable v-close-popup>
                            <q-item-section>{{child.name}}</q-item-section>
                        </q-item>
                    </template>
                </q-list>
            </q-menu>
        </div>
    </template>
</template>
import axios from 'axios'

const url_path = '/api/v1/'

export default {

    install: (app, options) => {
        const _get = (url, params) => {
            return axios.get(url_path+url,{params: params}).then(function(response){
                return response;
            });
        };

        app.config.globalProperties.fetchData = _get;

        app.provide("fetchData", _get)
    },
}
<script setup>
    import { inject, ref } from 'vue'
    const fetch = inject("fetchData")

    const slide= ref('style')

</script>

<template>
<div id="lipsum">
<p>
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet libero orci. Donec imperdiet leo vitae dapibus maximus. Aliquam aliquam scelerisque vehicula. Vivamus ut iaculis est, ac facilisis leo. In hac habitasse platea dictumst. Cras lobortis leo nisi, eu mattis leo consectetur eu. Etiam eget risus enim. Sed id malesuada odio. Aliquam id lorem efficitur, lacinia dui a, vestibulum ex. Nam odio sem, pretium ut hendrerit eget, venenatis vitae quam. Donec aliquet non lectus eget lobortis. Integer ullamcorper lorem blandit orci lacinia, sed pellentesque lectus viverra. Sed id tempus velit. Donec facilisis leo ut commodo lacinia. Morbi rhoncus eu odio at fermentum. Nam ut sodales turpis, vitae varius lorem.
</p>
<p>
Praesent volutpat vitae nibh vel mollis. Suspendisse ultricies sollicitudin egestas. Integer bibendum ante est, a tincidunt felis accumsan vitae. Suspendisse potenti. Fusce quis consectetur orci, sed blandit ex. Vivamus molestie blandit lorem, a sollicitudin neque facilisis in. In non leo quis est imperdiet commodo imperdiet non nulla. Pellentesque nec felis vehicula nisl sodales commodo. Donec cursus, metus ullamcorper dignissim pretium, quam orci cursus arcu, ac dignissim tortor ipsum vel nulla. Proin in elementum orci. Morbi tempus sit amet libero at rhoncus. Aenean id mauris id arcu vestibulum faucibus.
</p>
<p>
Morbi et lorem tempor, auctor velit sit amet, lobortis metus. Maecenas fermentum, nunc eu facilisis venenatis, tortor ipsum aliquet nulla, ac mollis lorem justo eu tortor. Sed finibus est et nibh vulputate, sit amet mattis urna cursus. Ut blandit, elit ac dapibus euismod, ante mauris fringilla eros, a hendrerit dui augue ut libero. Integer sed tortor lectus. Donec orci massa, condimentum nec tincidunt ac, viverra nec justo. Pellentesque posuere felis in porttitor blandit. Aenean condimentum, ligula vel laoreet rutrum, mi sem tempor augue, at fringilla lectus est sit amet massa. Etiam sit amet convallis sem, ut mattis diam. Etiam auctor euismod augue vel viverra. Integer vestibulum fermentum diam. Praesent eleifend lacus ut justo rutrum dapibus. Nullam a felis in lorem venenatis ultricies non faucibus est.
</p>
<p>
Integer feugiat euismod turpis quis semper. Sed enim lorem, mollis eget metus hendrerit, condimentum lacinia lacus. Nulla finibus metus quis augue semper condimentum. Nam quis venenatis turpis. Sed euismod, justo ut tristique vehicula, orci justo euismod lorem, non accumsan nulla eros eget magna. Sed tempus mollis orci quis venenatis. Maecenas tempus, ex eu commodo facilisis, justo elit efficitur eros, a molestie dolor erat vitae est.
</p>
<p>
Duis libero eros, scelerisque nec porttitor in, condimentum ut urna. Mauris in tortor pulvinar, hendrerit sem non, pulvinar nulla. Proin ut libero vel eros imperdiet tincidunt a at nisl. Vestibulum rhoncus mollis felis, vel pulvinar ligula laoreet sit amet. Sed non sagittis elit. Nam commodo velit finibus lacus ornare gravida. Nulla in consequat ligula.
</p>
<p>
Nam efficitur dui et mauris iaculis, id convallis sapien vulputate. Aenean pellentesque, lectus sit amet sagittis tincidunt, tortor eros vestibulum diam, vitae lobortis ante nunc id ex. Mauris a diam quam. Cras ac interdum risus. Donec nec ligula pellentesque turpis accumsan mollis. Morbi cursus neque id suscipit consequat. Morbi vulputate lacus ac enim pretium, et fringilla leo aliquet. Integer sit amet tincidunt tellus. Sed fringilla quis erat in elementum.
</p>
<p>
Suspendisse eget suscipit orci. Nunc ullamcorper mauris risus, a malesuada dui condimentum a. Sed in libero imperdiet, vulputate erat quis, elementum eros. Maecenas placerat tempus elit semper placerat. Suspendisse potenti. Vestibulum quis odio ac tortor luctus faucibus. Suspendisse sit amet lacinia ex. Curabitur quis gravida ipsum. Nulla et interdum ante. Integer tempus sollicitudin eros ac venenatis. Ut dapibus elit sed odio aliquam, eu vehicula massa fermentum. Fusce vitae sapien varius, volutpat ex nec, convallis mauris. Sed in ante vel tortor ultricies aliquam varius nec tellus. Integer rhoncus velit velit.
</p>
<p>
Aliquam id imperdiet odio, sit amet imperdiet sem. Cras volutpat, nibh ac porta euismod, lorem velit scelerisque odio, eu pharetra mi nibh non enim. Donec vitae finibus augue, et scelerisque dolor. Quisque pharetra eget lorem ac suscipit. Suspendisse mollis a odio non malesuada. Fusce dictum nulla at nisi dapibus laoreet non dapibus nunc. Pellentesque congue justo et sem posuere, eget bibendum mi aliquam. Ut mollis dui ex, sit amet gravida lacus congue non. Vivamus lobortis diam id dolor ornare vestibulum. Phasellus luctus ut nisi eget accumsan. Nunc sit amet ornare ipsum. Vestibulum nibh odio, vehicula maximus ligula quis, venenatis tincidunt massa. Morbi quam ligula, fermentum vitae mi sit amet, molestie porttitor tellus. Curabitur nulla nisl, consequat quis nisl suscipit, viverra viverra sapien. Donec nec velit pretium dolor aliquam egestas. Aenean est eros, suscipit vel lacus non, faucibus feugiat dui.
</p>
<p>
Proin hendrerit suscipit tempor. Morbi in est vel elit malesuada pharetra eget et risus. Donec erat diam, pretium sit amet suscipit eget, tempus a tortor. Proin pharetra nulla et dui tristique aliquam. Sed metus sem, fringilla vel lectus ac, varius iaculis lorem. Sed efficitur nisl cursus dignissim viverra. Maecenas bibendum nunc eu lacinia faucibus. Curabitur vehicula fringilla eleifend. Donec laoreet sapien eu diam porttitor, sit amet pretium ex volutpat. Nulla condimentum fringilla nunc, vel placerat mauris rutrum nec.
</p>
<p>
Nullam dignissim urna eget tempor convallis. Integer egestas ligula at arcu tincidunt commodo. Nunc aliquam efficitur sagittis. Phasellus lacinia vestibulum est eu sagittis. Praesent tincidunt nulla lorem, nec tincidunt libero dignissim sit amet. Interdum et malesuada fames ac ante ipsum primis in faucibus. Duis dictum vestibulum tincidunt. Cras lacinia egestas mauris, ut euismod metus posuere a. Aenean cursus massa in metus dignissim hendrerit. Suspendisse nec arcu et purus viverra interdum id aliquet libero. Nam a mauris eu mi eleifend suscipit non sed enim. Etiam tristique eu lorem fermentum ultricies. Nunc iaculis elit nec commodo pellentesque. Donec consectetur dui a pretium euismod.
</p>
<p>
Sed vitae sagittis augue. Suspendisse scelerisque consequat dui eu pretium. Morbi rhoncus tellus mauris, ut semper purus porttitor id. Proin bibendum, turpis in pharetra finibus, turpis velit tempor nibh, nec dictum orci lectus sit amet lacus. Nulla fringilla fringilla augue et iaculis. Suspendisse scelerisque dignissim sagittis. In id tellus lacinia, euismod ligula cursus, viverra metus. In tristique nulla sed nisl feugiat, quis congue augue bibendum. Donec viverra sem vulputate mi tincidunt, quis consectetur dui gravida. Fusce id erat porta, placerat ligula vel, pharetra sem. Donec in nisi semper, pretium ante et, venenatis orci.
</p>
<p>
Phasellus vel consectetur odio. Nam a ultrices lorem, ac maximus nunc. Nulla tellus libero, sollicitudin ut posuere vel, tempus vitae quam. Quisque at ligula tempus, efficitur sem eget, vulputate massa. Etiam ultricies blandit tincidunt. Suspendisse mi sapien, placerat in ultrices id, ornare eget sem. Ut viverra non tortor vel pharetra. Sed auctor velit eu gravida ultrices.
</p></div>

</template>
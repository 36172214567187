<script setup>
    import { inject, ref } from 'vue'
    const fetch = inject("fetchData")

    const slide= ref('style')

</script>

<template>
    <q-carousel
        v-model="slide"
        transition-prev="scale"
        transition-next="scale"
        swipeable
        animated
        infinite
        autoplay
        control-color="black"
        navigation
        padding
        arrows
        class="bg-primary text-white shadow-1 rounded-borders"
      >
        <q-carousel-slide name="1" class="column no-wrap flex-center">
            <img src="../../assets/images/slide1.jpg" />
        </q-carousel-slide>
        <q-carousel-slide name="2" class="column no-wrap flex-center">
            <img src="../../assets/images/slide2.jpg" />
        </q-carousel-slide>
        <q-carousel-slide name="3" class="column no-wrap flex-center">
            <img src="../../assets/images/slide3.jpg" />
        </q-carousel-slide>
        <q-carousel-slide name="4" class="column no-wrap flex-center">
            <img src="../../assets/images/slide4.jpg" />
        </q-carousel-slide>
      </q-carousel>

      <div style="height: 300px; background-color: brown;"></div>
      <div style="height: 200px; background-color: green;"></div>
      <div style="height: 400px; background-color: blueviolet;"></div>


      <div style="height: 300px; background-color: brown;"></div>
      <div style="height: 200px; background-color: green;"></div>
      <div style="height: 400px; background-color: blueviolet;"></div>


      <div style="height: 300px; background-color: brown;"></div>
      <div style="height: 200px; background-color: green;"></div>
      <div style="height: 400px; background-color: blueviolet;"></div>

</template>
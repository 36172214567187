import { createWebHistory, createRouter } from 'vue-router'

import Home from '../app/Home.vue'
import About from '../app/About.vue'

const routes = [
  { path: '/', component: Home, name: "eamf.lv" },
  { path: '/about', component: About, name: "About" }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  document.title = to.name || "EAMF";
  next();
});

export default router
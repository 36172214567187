import {createI18n} from "vue-i18n";

// List of all locales.
const allLocales = ["en", "lv"];

// Create Vue I18n instance.
const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: "en",
  fallbackLocale: "lv",
  messages: window.lang,
});

export default i18n